<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <!-- Inscription -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-receipt-clock-outline"
          title="Clôture dans"
          :value="cloture_delay"
          sub-icon="mdi-calendar"
          :sub-text="`Fin des incriptions le ${cloture}`"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <base-material-stats-card
          color="primary "
          icon="mdi-timer-pause-outline"
          title="Inscription en attente"
          :value="`${inscription_attente}`"
          sub-icon="mdi-calendar"
          sub-text="Mise à jour aujourd'hui"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <base-material-stats-card
          color="primary "
          icon="mdi-playlist-check"
          title="Inscrits"
          :value="`${inscription_valid}`"
          sub-icon="mdi-calendar"
          sub-text="Mise à jour aujourd'hui"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <base-material-stats-card
          color="primary "
          icon="mdi-currency-eur"
          title="CA"
          :value="`${inscription_ca} €`"
          sub-icon="mdi-calendar"
          sub-text="Mise à jour aujourd'hui"
        />
      </v-col>
      <!-- Inscription -->
    </v-row>
    <v-row>
      <!-- Age -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
        v-for="(item,index) in inscription_age"
        :key="index"
      >
        <base-material-stats-card
          color="primary "
          icon="mdi-counter"
          :title="`${index}`"
          :value="`${item}`"
          sub-icon="mdi-calendar"
          sub-text="Mise à jour aujourd'hui"
        />
      </v-col>

      <!-- <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <base-material-stats-card
          color="primary "
          icon="mdi-counter"
          title="7-9 ans"
          :value="construct_categorie_stat('7-9')"
          sub-icon="mdi-calendar"
          sub-text="Mise à jour aujourd'hui"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <base-material-stats-card
          color="primary "
          icon="mdi-counter"
          title="12-15 ans"
          :value="construct_categorie_stat('12-15')"
          sub-icon="mdi-calendar"
          sub-text="Mise à jour aujourd'hui"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <base-material-stats-card
          color="primary "
          icon="mdi-counter"
          title="15-77 ans"
          :value="construct_categorie_stat('15-77')"
          sub-icon="mdi-calendar"
          sub-text="Mise à jour aujourd'hui"
        />
      </v-col> -->
<!-- Age -->
</v-row>
<v-row>
      <!-- Genre -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-face-woman"
          title="Enfant / Fille"
          :value="`${inscription_genre['fille']}`"
          sub-icon="mdi-calendar"
          sub-text="Mise à jour aujourd'hui"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <base-material-stats-card
          color="primary"
          icon="mdi-face-man"
          title="Enfant / Garçon"
          :value="`${inscription_genre['garcon']}`"
          sub-icon="mdi-calendar"
          sub-text="Mise à jour aujourd'hui"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <base-material-stats-card
          color="primary "
          icon="mdi-face-woman"
          title="Adulte / Femme"
          :value="`${inscription_genre['femme']}`"
          sub-icon="mdi-calendar"
          sub-text="Mise à jour aujourd'hui"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <base-material-stats-card
          color="primary "
          icon="mdi-face-man"
          title="Adulte / Homme"
          :value="`${inscription_genre['homme']}`"
          sub-icon="mdi-calendar"
          sub-text="Mise à jour aujourd'hui"
        />
      </v-col>
      <!-- Genre -->
    </v-row>
  <v-row>
      <!-- Chart -->
      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="subscriptionChart1.data"
          :options="subscriptionChart1.options"
          :responsive-options="subscriptionChart1.responsiveOptions"
          color="primary "
          hover-reveal
          type="Bar"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="info"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Inscription
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Performance N-1
          </p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="text-caption grey--text font-weight-light">mis à jour aujourd'hui</span>
          </template>
        </base-material-chart-card>
      </v-col>
      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="subscriptionChart.data"
          :options="subscriptionChart.options"
          :responsive-options="subscriptionChart.responsiveOptions"
          color="primary "
          hover-reveal
          type="Bar"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="info"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Inscription
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Performance N
          </p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="text-caption grey--text font-weight-light">mis à jour aujourd'hui</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <!-- <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="dailySalesChart.data"
          :options="dailySalesChart.options"
          color="primary "
          hover-reveal
          type="Line"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="info"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Daily Sales
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            <v-icon
              color="green"
              small
            >
              mdi-arrow-up
            </v-icon>
            <span class="green--text">55%</span>&nbsp;
            increase in today's sales
          </p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="text-caption grey--text font-weight-light">updated 4 minutes ago</span>
          </template>
        </base-material-chart-card>
      </v-col> -->

      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="dataCompletedTasksChart.data"
          :options="dataCompletedTasksChart.options"
          hover-reveal
          color="primary "
          type="Line"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="info"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h3 class="card-title font-weight-light mt-2 ml-2">
            Inscription
          </h3>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Performance N-1/N
          </p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="text-caption grey--text font-weight-light">mis à jour aujourd'hui</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <!-- <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-twitter"
          title="Followers"
          value="+245"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-poll"
          title="Website Visits"
          value="75.521"
          sub-icon="mdi-tag"
          sub-text="Tracked from Google Analytics"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary "
          icon="mdi-store"
          title="Revenue"
          value="$ 34,245"
          sub-icon="mdi-calendar"
          sub-text="Last 24 Hours"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="orange"
          icon="mdi-sofa"
          title="Bookings"
          value="184"
          sub-icon="mdi-alert"
          sub-icon-color="red"
          sub-text="Get More Space..."
        />
      </v-col> -->

      <!-- <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          color="warning"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Employees Stats
            </div>

            <div class="text-subtitle-1 font-weight-light">
              New employees on 15th September, 2016
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
            />
          </v-card-text>
        </base-material-card>
      </v-col> -->

      <!-- <v-col
        cols="12"
        md="12"
      >
        <base-material-card class="px-5 py-3">
          <template v-slot:heading>
            <v-tabs
              v-model="tabs"
              background-color="transparent"
              slider-color="white"
            >
              <span
                class="subheading font-weight-light mx-3"
                style="align-self: center"
              >Taches:</span>
              <v-tab class="mr-3">
                Inscription
              </v-tab>
              <v-tab class="mr-3">
                <v-icon class="mr-2">
                  mdi-code-tags
                </v-icon>
                Website
              </v-tab>
              <v-tab>
                <v-icon class="mr-2">
                  mdi-cloud
                </v-icon>
                Server
              </v-tab>
            </v-tabs>
          </template>

          <v-tabs-items
            v-model="tabs"
            class="transparent"
          >
            <v-tab-item
              v-for="n in 3"
              :key="n"
            >
              <v-card-text>
                <template v-for="(task, i) in tasks[tabs]">
                  <v-row
                    align="center"
                  >
                    <v-col cols="1">
                      <v-list-item-action>
                        <v-checkbox
                          v-model="task.value"
                          color="secondary"
                        />
                      </v-list-item-action>
                    </v-col>

                    <v-col cols="12">
                      construction
                    </v-col>

                    <v-col
                      cols="2"
                      class="text-right"
                    >
                      <v-icon class="mx-1">
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        color="error"
                        class="mx-1"
                      >
                        mdi-close
                      </v-icon>
                    </v-col>
                  </v-row>
                </template>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </base-material-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import stat from '../../services/stat'
  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        inscription_month:{n:[]},
        inscription_genre:{},
        inscription_age:{},
        categories_age:{},
        inscription_ca:"",
        inscription_valid:"",
        inscription_attente:"",
        cloture:"",
        cloture_delay:"",
        dailySalesChart: {
          data: {
            labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            series: [
              [12, 17, 7, 17, 23, 18, 38],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        dataCompletedTasksChart: {
          data: {
            labels: ['Se', 'Oc', 'No', 'De', 'Ja', 'Fe', 'Ma', 'Av', 'Mai', 'Ju', 'Jul', 'Au'],
            series: [
            
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: -50,
            high: 300, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        subscriptionChart: {
          data: {
            labels: [ 'Se', 'Oc', 'No', 'De', 'Ja', 'Fe', 'Ma', 'Av', 'Mai', 'Ju', 'Jul', 'Au'],
            series:[ ],
          },
          options: {
            axisX: {
              showGrid: false,
            },
            low: 0,
            high: 300,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
        subscriptionChart1: {
          data: {
            labels: [ 'Se', 'Oc', 'No', 'De', 'Ja', 'Fe', 'Ma', 'Av', 'Mai', 'Ju', 'Jul', 'Au'],
            series:[ ],
          },
          options: {
            axisX: {
              showGrid: false,
            },
            low: 0,
            high: 300,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
        headers: [
          {
            sortable: false,
            text: 'ID',
            value: 'id',
          },
          {
            sortable: false,
            text: 'Name',
            value: 'name',
          },
          {
            sortable: false,
            text: 'Salary',
            value: 'salary',
            align: 'right',
          },
          {
            sortable: false,
            text: 'Country',
            value: 'country',
            align: 'right',
          },
          {
            sortable: false,
            text: 'City',
            value: 'city',
            align: 'right',
          },
        ],
        items: [
          {
            id: 1,
            name: 'Dakota Rice',
            country: 'Niger',
            city: 'Oud-Tunrhout',
            salary: '$35,738',
          },
          {
            id: 2,
            name: 'Minerva Hooper',
            country: 'Curaçao',
            city: 'Sinaai-Waas',
            salary: '$23,738',
          },
          {
            id: 3,
            name: 'Sage Rodriguez',
            country: 'Netherlands',
            city: 'Overland Park',
            salary: '$56,142',
          },
          {
            id: 4,
            name: 'Philip Chanley',
            country: 'Korea, South',
            city: 'Gloucester',
            salary: '$38,735',
          },
          {
            id: 5,
            name: 'Doris Greene',
            country: 'Malawi',
            city: 'Feldkirchen in Kārnten',
            salary: '$63,542',
          },
        ],
        tabs: 0,
        tasks: {
          0: [
            {
              text: 'Sign contract for "What are conference organizers afraid of?"',
              value: true,
            },
            {
              text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
              value: false,
            },
            {
              text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
              value: false,
            },
            {
              text: 'Create 4 Invisible User Experiences you Never Knew About',
              value: true,
            },
          ],
          1: [
            {
              text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
              value: true,
            },
            {
              text: 'Sign contract for "What are conference organizers afraid of?"',
              value: false,
            },
          ],
          2: [
            {
              text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
              value: false,
            },
            {
              text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
              value: true,
            },
            {
              text: 'Sign contract for "What are conference organizers afraid of?"',
              value: true,
            },
          ],
        },
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },

    methods: {
      complete (index) {
        this.list[index] = !this.list[index]
      },
      construct_categorie_stat(tranche){
        let text =""
        this.categories_age[tranche]?.forEach(element => {
          text+=`<div><span style="font-size:12px !important;">${element.niveau} :</span> ${element.count}</div>`
        });
        return text
      },
      async calc_stat(){
        return await stat.getStat()
      }
    },
   async mounted(){
      const {cloture,inscription_attente,inscription_valid,inscription_ca,categories_age,inscription_age,inscription_genre,inscription_month} = await this.calc_stat()
      console.log("cloture",cloture)
      this.cloture = this.$moment(cloture).format("DD/MM/YYYY")
      const now = this.$moment().format("YYYY-MM-DD")
      const end = this.$moment(cloture).format("YYYY-MM-DD")
      const delay = this.$moment(end).diff(this.$moment(now),"day")
      // this.cloture = this.$moment(cloture).format("DD/MM/YYYY")
      // const delay = this.$moment.duration(this.$moment(this.$moment(cloture).format("YYYY-MM-DD")).diff(this.$moment(this.$moment().format("YYYY-MM-DD"))))
      this.cloture_delay = `${delay} jours`
      // this.cloture_delay = `${delay.days()} jours, ${delay.hours()} heures, ${delay.minutes()} minutes, ${delay.seconds()} secondes`
      this.inscription_attente =inscription_attente
      this.inscription_valid =inscription_valid
      this.inscription_ca =inscription_ca
      this.categories_age =categories_age
      this.inscription_age =inscription_age
      this.inscription_genre =inscription_genre
      this.subscriptionChart.data.series.push(inscription_month.n)
      this.subscriptionChart1.data.series.push(inscription_month.n1)
      this.dataCompletedTasksChart.data.series.push(inscription_month.n1_n)
    }
  }
</script>
