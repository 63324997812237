import Api from '../../config/config.js'

export default {
    async getStat(){
        try {
            let getStat = await Api.instance.get(`/generate-stat`)
            console.log("getStat",getStat.data)
            return getStat.data

        } catch (error) {
            
        }
    }
}